// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReasonReact from "reason-react/src/ReasonReact.js";
import * as Utils$SystemaFr from "../utils/Utils";
import * as Layout$SystemaFr from "../components/Layout";
import * as Markdown$SystemaFr from "../components/Markdown";

import "../assets/css/news.css"
;

var noEventView = React.createElement("div", {
      className: "no-content-placeholder mdl-grid"
    }, React.createElement("div", {
          className: "mdl-cell mdl-cell--12-col"
        }, React.createElement("div", {
              className: "material-icons md-dark"
            }, Utils$SystemaFr.text("event_busy")), React.createElement("br", undefined), React.createElement("em", undefined, Utils$SystemaFr.text("Pas de stage prévu pour le moment"))));

function eventDetailView($staropt$star, textIcon, content) {
  var icon = $staropt$star !== undefined ? $staropt$star : "null";
  return React.createElement("div", undefined, React.createElement("span", {
                  className: "mdl-button mdl-js-button mdl-button--icon mdl-js-ripple-effect"
                }, textIcon !== undefined ? Utils$SystemaFr.text(textIcon) : React.createElement("div", {
                        className: "material-icons md-dark"
                      }, Utils$SystemaFr.text(icon))), Utils$SystemaFr.nbsp, Utils$SystemaFr.nbsp, content);
}

function eventDetailTextView(icon, textIcon, $staropt$star, textContent) {
  var strong = $staropt$star !== undefined ? $staropt$star : false;
  var content = strong ? React.createElement("strong", undefined, Utils$SystemaFr.text(textContent)) : Utils$SystemaFr.text(textContent);
  return eventDetailView(icon, textIcon, content);
}

function eventOptionalDetailView(icon, textIcon, strong, optionalText) {
  return Utils$SystemaFr.optionalView(optionalText, (function (param) {
                return eventDetailTextView(icon, textIcon, strong, param);
              }));
}

function eventView(archive, $$event) {
  var statusClass = archive ? "news--archive" : "";
  var filesView = function (documents) {
    return Belt_List.toArray(Belt_List.map(documents, (function ($$document) {
                      var href = $$document.publicURL;
                      return React.createElement("a", {
                                  key: href,
                                  href: href
                                }, Utils$SystemaFr.text($$document.name));
                    })));
  };
  return React.createElement("div", {
              key: $$event.id,
              className: statusClass + " mdl-cell mdl-cell--12-col"
            }, React.createElement("div", {
                  className: "mdl-card mdl-shadow--2dp"
                }, React.createElement("div", {
                      className: "news__overlay"
                    }), React.createElement("div", {
                      className: "mdl-card__title mdl-card--border"
                    }, React.createElement("h2", {
                          className: "mdl-card__title-text"
                        }, React.createElement("a", {
                              className: "mdl-button mdl-js-button mdl-button--icon mdl-js-ripple-effect",
                              href: "#"
                            }, React.createElement("div", {
                                  className: "material-icons md-dark"
                                }, Utils$SystemaFr.text("event"))), Utils$SystemaFr.nbsp, Utils$SystemaFr.nbsp, Utils$SystemaFr.text($$event.dates))), React.createElement("div", {
                      className: "mdl-card__supporting-text"
                    }, React.createElement("h4", undefined, Utils$SystemaFr.text($$event.name)), ReasonReact.element(undefined, undefined, Markdown$SystemaFr.make("event__description", $$event.description, []))), React.createElement("div", {
                      className: "mdl-card__actions mdl-card--border"
                    }, eventOptionalDetailView("schedule", undefined, true, $$event.time), eventOptionalDetailView("school", undefined, true, $$event.instructor), eventOptionalDetailView("room", undefined, undefined, $$event.location), eventOptionalDetailView("directions_subway", undefined, undefined, $$event.train), eventOptionalDetailView("directions_car", undefined, undefined, $$event.car), eventOptionalDetailView("info", undefined, undefined, $$event.registration), eventOptionalDetailView(undefined, "€", undefined, $$event.fee), Belt_List.length($$event.documents) > 0 ? eventDetailView("attach_file", undefined, filesView($$event.documents)) : null)));
}

var noNewsView = React.createElement("div", {
      className: "mdl-cell mdl-cell--12-col"
    }, React.createElement("div", {
          className: "no-content-placeholder mdl-grid"
        }, React.createElement("div", {
              className: "mdl-cell mdl-cell--12-col"
            }, React.createElement("div", {
                  className: "material-icons md-dark"
                }, Utils$SystemaFr.text("flip_to_back")), React.createElement("br", undefined), React.createElement("em", undefined, Utils$SystemaFr.text("Aucune annonce")))));

function newsView(news) {
  return React.createElement("div", {
              key: news.id,
              className: "mdl-cell mdl-cell--12-col"
            }, React.createElement("div", {
                  className: "mdl-card mdl-shadow--2dp"
                }, React.createElement("div", {
                      className: "news__overlay"
                    }), React.createElement("div", {
                      className: "mdl-card__title mdl-card--border"
                    }, React.createElement("h2", {
                          className: "mdl-card__title-text"
                        }, React.createElement("a", {
                              className: "mdl-button mdl-js-button mdl-button--icon mdl-js-ripple-effect",
                              href: "#"
                            }, React.createElement("div", {
                                  className: "material-icons md-dark"
                                }, Utils$SystemaFr.text("description"))), Utils$SystemaFr.nbsp, Utils$SystemaFr.nbsp, Utils$SystemaFr.text(news.title))), React.createElement("div", {
                      className: "mdl-card__supporting-text"
                    }, ReasonReact.element(undefined, undefined, Markdown$SystemaFr.make(undefined, news.content, [])))));
}

var component = ReasonReact.statelessComponent("News");

function make(data, _children) {
  var events = Belt_List.map(Belt_List.fromArray(data.events.edges), (function ($$event) {
          var node = $$event.node;
          var frontmatter = node.frontmatter;
          var match = frontmatter.document;
          var documents = (match == null) ? /* [] */0 : /* :: */[
              {
                name: match.name,
                publicURL: match.publicURL
              },
              /* [] */0
            ];
          return {
                  id: $$event.node.id,
                  name: $$event.node.frontmatter.name,
                  dates: $$event.node.frontmatter.date,
                  startDateTime: $$event.node.frontmatter.startDateTime,
                  endDateTime: Caml_option.nullable_to_opt($$event.node.frontmatter.endDateTime),
                  description: $$event.node.html,
                  time: Caml_option.nullable_to_opt($$event.node.frontmatter.time),
                  instructor: Caml_option.nullable_to_opt($$event.node.frontmatter.instructor),
                  location: Caml_option.nullable_to_opt($$event.node.frontmatter.location),
                  train: Caml_option.nullable_to_opt($$event.node.frontmatter.train),
                  car: Caml_option.nullable_to_opt($$event.node.frontmatter.car),
                  registration: Caml_option.nullable_to_opt($$event.node.frontmatter.registration),
                  fee: Caml_option.nullable_to_opt($$event.node.frontmatter.fee),
                  documents: documents
                };
        }));
  var news = Belt_List.reduce(Belt_List.fromArray(data.news.edges), /* [] */0, (function (acc, piece) {
          if (piece.node.frontmatter.archive) {
            return acc;
          } else {
            var piece_id = piece.node.id;
            var piece_title = piece.node.frontmatter.title;
            var piece_archive = piece.node.frontmatter.archive;
            var piece_content = piece.node.html;
            var piece$1 = {
              id: piece_id,
              title: piece_title,
              archive: piece_archive,
              content: piece_content
            };
            return /* :: */[
                    piece$1,
                    acc
                  ];
          }
        }));
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              var tmp;
              if (Belt_List.length(events) === 0) {
                tmp = noEventView;
              } else {
                var now = Curry._1(Utils$SystemaFr.$$Date.now, /* () */0);
                var match = Belt_List.partition(events, (function ($$event) {
                        var endDateTime = Belt_Option.getWithDefault($$event.endDateTime, $$event.startDateTime);
                        return now > endDateTime;
                      }));
                tmp = Belt_List.toArray(Belt_List.concat(Belt_List.map(match[1], (function (param) {
                                return eventView(false, param);
                              })), Belt_List.map(match[0], (function (param) {
                                return eventView(true, param);
                              }))));
              }
              return ReasonReact.element(undefined, undefined, Layout$SystemaFr.make("Actualités", "news-layout", [React.createElement("div", {
                                    className: "content"
                                  }, React.createElement("div", {
                                        className: "page-container mdl-grid mdl-grid--no-spacing"
                                      }, React.createElement("div", {
                                            className: "mdl-cell mdl-cell--1-col mdl-cell--hide-tablet mdl-cell--hide-phone"
                                          }), React.createElement("div", {
                                            className: "mdl-cell mdl-cell--5-col"
                                          }, React.createElement("div", {
                                                className: "mdl-grid"
                                              }, React.createElement("h2", {
                                                    className: "news-category__title"
                                                  }, Utils$SystemaFr.text("Actualités")), Belt_List.length(news) === 0 ? noNewsView : Belt_List.toArray(Belt_List.map(news, newsView)))), React.createElement("div", {
                                            className: "mdl-cell mdl-cell--5-col"
                                          }, React.createElement("div", {
                                                className: "mdl-grid"
                                              }, React.createElement("h2", {
                                                    className: "news-category__title"
                                                  }, Utils$SystemaFr.text("Événements")), tmp))))]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var $$default = ReasonReact.wrapReasonForJs(component, (function (jsProps) {
        return make(jsProps.data, jsProps.children);
      }));

export {
  noEventView ,
  eventDetailView ,
  eventDetailTextView ,
  eventOptionalDetailView ,
  eventView ,
  noNewsView ,
  newsView ,
  component ,
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
