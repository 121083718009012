import News from '../templates/News.re'
import { graphql } from 'gatsby'

export default News

export const query = graphql`
{

  news: allMarkdownRemark (
    limit: 100
    filter: {
      fields: { sourceInstanceName: { eq: "news" }},
      frontmatter: { archive: { eq: false }}
    }
    sort: { fields: [frontmatter___date], order: ASC }
  ) {
    edges {
      node {
        id
        frontmatter {
          title
          date
          author
          archive
        }
        html
      }
    }
  }

  events: allMarkdownRemark (
    limit: 100
    filter: { fields: { sourceInstanceName: { eq: "events" }}}
    sort: { fields: [frontmatter___startDateTime], order: ASC }
  ) {
    edges {
      node {
        id
        frontmatter {
          title
          startDateTime(formatString: "x")
          endDateTime(formatString: "x")
          date
          time
          instructor
          location
          train
          car
          registration
          fee
          document {
            name
            publicURL
          }
        }
        html
      }
    }
  }

}
`
// Strapi query
  // files: allFile(
    // filter: { sourceInstanceName: { eq: "uploads" } }
  // ) {
    // edges {
      // node {
        // name
        // publicURL
      // }
    // }
  // }

  // allStrapiNews(
    // limit: 100
    // filter: { archive: { eq: false }}
    // sort: { fields: [createdAt], order: DESC }
  // ) {
    // edges {
      // node {
        // id
        // title
        // archive
        // content {
          // childMarkdownRemark {
            // html
          // }
        // }
      // }
    // }
  // }
  // allStrapiEvents(
    // limit: 100
    // sort: { fields: [startDate], order: ASC }
  // ) {
    // edges {
      // node {
        // id
        // name
        // dates
        // startDate(formatString: "x")
        // endDate(formatString: "x")
        // description {
          // childMarkdownRemark {
            // html
          // }
        // }
        // time
        // instructor
        // location
        // train
        // car
        // registration
        // fee
        // documents {
          // name
          // hash
          // ext
        // }
      // }
    // }
  // }
